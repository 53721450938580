const env = process.env.REACT_APP_ENVIRONMENT || "development";

const config = {
  development: {
    apiEndpoint: "https://localhost:5019"
  },
  staging: {
    apiEndpoint: "https://api.rtsh.dev"
  },
  production: {
    apiEndpoint: "https://api.rtsh.al"
  }
};

export default config[env];
