import React, { useState, useEffect } from "react";
import { Menu, Layout as AntLayout } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import { navigationPathMapping, NaviationMapping } from "./index";
import { CHANGE_PASSWORD_PATH, MY_PROFILE_PATH } from "./index";

const { Sider } = AntLayout;

const ManageProfileWrapper = ({ children }) => {
  const history = useHistory();
  let location = useLocation();

  const [activeKey, setActiveKey] = useState();

  useEffect(() => {
    let pathname = location.pathname;
    const split = pathname.split("/")[2];

    const res = navigationPathMapping.get(split);
    setActiveKey(
      res ? [res.toString()] : [NaviationMapping.MyProfile.toString()]
    );
  }, [location]);

  const goTo = path => {
    history.push(path);
  };

  return (
    <AntLayout>
      <AntLayout className="dashboard_body">
        <Sider width={240}>
          <Menu
            selectedKeys={activeKey}
            mode="inline"
            className="dashboard-nav"
          >
            <Menu.Item key="1" onClick={() => goTo(MY_PROFILE_PATH)}>
              Profili im
            </Menu.Item>
            <Menu.Item key="2" onClick={() => goTo(CHANGE_PASSWORD_PATH)}>
              Nrysho fjalëkalimin
            </Menu.Item>
          </Menu>
        </Sider>
        <AntLayout className="dashboard-layout-content">{children}</AntLayout>
      </AntLayout>
    </AntLayout>
  );
};

export default ManageProfileWrapper;
