import { adminHttpClient } from "../commons/adminHttpClient";
import AudioFilter from "./audioFilter";


const controller = "/audio";

export default {
  getAll: async (filter = new AudioFilter()) => {
    if (!filter) {
      throw new Error("Filtri nuk duhet të jetë bosh.");
    }

    return adminHttpClient.get(
      `/audio?PageNumber=${filter.pageNumber}&orderColumn=${filter.orderColumn}&orderDescending=${filter.orderDescending}&categoryId=${filter.categoryId}`
    );
  },
  delete: async id => adminHttpClient.delete(`${controller}/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/audio/forSave/${id}`);
    }
    return adminHttpClient.get(`/audio/forSave`);
  },
  save: async values => adminHttpClient.post(`/audio`, values)
};