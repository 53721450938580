import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useHistory, useParams} from "react-router-dom";
import Card from "antd/es/card";
import {Form as FormikForm, Formik} from "formik";
import Uploader from "../common/components/forms/Uploader";
import {Spin} from "antd";
import Row from "antd/es/grid/row";

import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import videos from "core/application/videos/video";
import {commandRequest, queryRequest} from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";

const contactFormValidator = Yup.object({
  name: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  photoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  fileId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManageVideo = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko videon" : "Shto video";

  const updateForm = async () => {
    const video = await queryRequest(() => videos.getForSave(id));
    setItem(video);
  };

  useEffect(() => {
    (async () => {
      await updateForm();
      setLoading(false);
    })();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    const result = await commandRequest(() => videos.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e videos u krye me sukses."
      );
      history.push(getSiteUrl("/video"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Titulli"
                    name="name"
                    type="text"
                    placeholder="Titulli i videos"
                    onBlur={e => {
                      props.setFieldValue("name", e.currentTarget.value);
                    }}
                  />
                </Row>

                <Row>
                  <Uploader
                    label="Foto (640 x 426)"
                    initialValue={item.photoId}
                    name="photoId"
                    resizeToWidth={640}
                    resizeToHeight={426}
                    fieldName="photoId"
                  />
                </Row>

                <Row>
                  <Uploader
                      label="Video"
                      initialValue={item.fileId}
                      name="fileId"
                      fieldName="fileId"
                      viewYoutubeChooser={true}
                  />
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageVideo;
