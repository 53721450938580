import { adminHttpClient } from "../commons/adminHttpClient";
import userStore from "./userStore";

const refreshUserInStore = () => {
  return new Promise(resolve => {
    adminHttpClient
      .get("/users/me")
      .then(user => {
        userStore.setUser(user);
        resolve(user);
      })
      .catch(() => {
        userStore.setUser(null);
      });
  });
};

export default refreshUserInStore;
