import User from "./user";

const Keys = {
  USER: "user"
};

const userStore = {
  setUser: user => {
    localStorage.setItem(Keys.USER, user ? JSON.stringify(user) : null);
  },
  getUser: () => {
    try {
      const jsonParsed = JSON.parse(localStorage.getItem(Keys.USER));
      return jsonParsed ? new User({ ...jsonParsed }) : null;
    } catch (e) {
      return null;
    }
  }
};

export default userStore;
