import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import openNotification from "../common/components/Notification";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import { Spin } from "antd";
import Row from "antd/es/grid/row";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import audios from "core/application/audios/audio";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";
import audioCategories from "core/application/audios/categories/audioCategories";

const contactFormValidator = Yup.object({
  name: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
  fileId: Yup.string().nullable().trim().required("Fushë e detyrueshme")
});

const ManageAudio = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id, podcastId } = useParams();
  const pageTitle = id ? "Modifiko episode" : "Krijo episode";
  const [podcast, setPodcast] = useState(null);

  const updateForm = async () => {
    const audio = await queryRequest(() => audios.getForSave(id));
    setItem(audio);
  };

  useEffect(() => {
    (async () => {
      const podcastResult = await queryRequest(() => audioCategories.getById(podcastId));
      setPodcast(podcastResult);

      await updateForm();
      setLoading(false);
    })();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    const request = {
      name:values.name,
      fileId: values.fileId,
      categoryId: podcastId,
      id: id
    }
    const result = await commandRequest(() => audios.save(request));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification("success", "Operacioni", "Ruajtja e podcast u krye me sukses.");
      history.push(getSiteUrl(`/podcasts/episodes/${podcastId}`));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      {item && podcast && (
      <Card title={`${pageTitle} per '${podcast.name}'`}>
         <ValidationSummary errors={errors} />
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit} >
            {props => (
              <FormikForm>
                <Row>
                  <Input label="Emri" type="text" name="name" placeholder="Emri i episodit"/>
                </Row>

                <Row>
                  <Uploader label="Skedari" initialValue={item.fileId} name="fileId" fieldName="fileId"/>
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
      </Card>
      )}
    </Spin>
  );
};

export default ManageAudio;
