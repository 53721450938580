import React from "react";
import styled from "styled-components";
import {useField, useFormikContext} from "formik";
import Error from "./FormItemError";
import {Select as AntSelect} from "antd";

const StyledLabel = styled.label`
  margin-top: 1rem;
`;

const filter = (input, option) => {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const Select = ({ defaultValue, mode, placeholder, label, onChanged, ...props }) => {
  const [field, meta] = useField(props);
  const context = useFormikContext();

  const onChange = (value, key) => {
      if (mode === "multiple" || mode === "tags") {
          context.setFieldValue(props.itemName, value);
      } else {
          context.setFieldValue(props.itemName, key.key ? key.key : null);
      }
      if (onChanged) {
          onChanged(value);
      }
  };

  return (
    <>
        <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
        <AntSelect
            defaultValue={defaultValue}
            placeholder={placeholder}
            mode={mode}
            showSearch
            filterOption={filter}
            onChange={onChange}
            style={{width: "300px"}}>
            {props.children}
        </AntSelect>
      {meta.touched || meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

export default Select;
