import React, { useContext, useState, useEffect } from "react";
import { Row, Table, Col, Spin } from "antd";
import { useParams } from "react-router-dom";
import galleryItems from "core/application/galleries/galleryItems";
import moment from "moment";
import { queryRequest } from "../common/utilities";

import TableEditButton from "../common/components/tables/TableEditButton";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import { AppContext } from "../AppContext";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const GalleryItems = () => {
  const { user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { friendlyUrl } = useParams();

  const updateTable = async () => {
    setLoading(true);

    const items = await queryRequest(() =>
      galleryItems.getGalleryWithItems(friendlyUrl)
    );
    setData(items);

    setLoading(false);
  };

  const columns = [
    {
      key: "fileUrl",
      title: "Foto",
      dataIndex: "fileUrl",
      width: "40%",
      render: value => <img src={value} width="50%" />
    },
    {
      key: "title",
      title: "Titulli",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend", "ascend"]
    },
    {
      key: "dateTaken",
      title: "Data",
      dataIndex: "dateTaken",
      render: value => moment(value).format("DD/MM/YYYY"),
      sorter: (a, b) => moment(a.dateTaken).unix() - moment(b.dateTaken).unix(),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <TableEditButton
            className="mr-16"
            editUrl={`/gallery-items/manage-gallery-item/${data.id}/${data.friendlyUrl}/${record.id}`}
            visible={user.canSaveArticle}
          />
          <TableDeleteButton
            onDelete={() => galleryItems.delete(record.id)}
            onDeleted={() => updateTable()}
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  useEffect(() => {
    updateTable();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>
            <a href={getSiteUrl(`/galleries`)}>Galeritë</a> /{" "}
            <a href={getSiteUrl(`/gallery-items/${friendlyUrl}`)}>
              {data.name}
            </a>
          </h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton
              addUrl={getSiteUrl(
                `/gallery-items/manage-gallery-item/${data.id}/${friendlyUrl}`
              )}
            />
          </div>
        </Col>
      </Row>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data.galleryItems}
        onChange={onChange}
      />
    </Spin>
  );
};

export default GalleryItems;
