import getCurrentSite from "./currentSite";

const getSiteUrl = path => {
  if (!path) {
    throw new Error("Path is not defined.");
  }
  const currentSite = getCurrentSite();
  if (!currentSite) {
    throw new Error("Current site is not defined.");
  }
  return `${currentSite.ROOT_PATH}${path}`;
};

export default getSiteUrl;
