import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Table, Button } from "antd";

import pages from "core/application/pages/page";
import { queryRequest } from "../common/utilities";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import AddButton from "../common/components/tables/AddButton";
import { displayDateAndHour } from "../common/displayUtilities";
import OrderedPaginationFilter from "core/application/commons/orderedPaginationFilter";
import PagedList from "../common/models/pagedList";
import { AppContext } from "../AppContext";
import getSiteUrl from "../siteUrlCreator";

const Page = () => {
  let history = useHistory();
  const { user } = useContext(AppContext);

  const [pagedList, setPagedList] = useState(new PagedList());
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(
    new OrderedPaginationFilter({
      orderColumn: "created"
    })
  );

  const updatePagedList = async () => {
    const result = await queryRequest(() => pages.getAll(filter));
    setPagedList(result);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await updatePagedList();
      setLoading(false);
    })();
  }, [filter]);

  const onChange = (pagination, filters, sorter) => {
    const articleFilter = new OrderedPaginationFilter({
      orderColumn: sorter.field,
      orderDescending: sorter.order !== "ascend",
      pageNumber: pagination.current
    });
    setFilter(articleFilter);
  };

  const columns = [
    {
      title: "Titulli",
      dataIndex: "title",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => <a className={"dark"}>{text}</a>
    },
    {
      title: "Krijuar në",
      dataIndex: "created",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          {user.canSaveArticle && (
            <Button
              className="mr-16"
              icon="edit"
              onClick={() => history.push(`/page/manage-page/${record.id}`)}
            />
          )}
          <TableDeleteButton
            onDelete={() => pages.delete(record.id)}
            onDeleted={() => updatePagedList()}
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  const columnToSort = columns.find(c => c.dataIndex === filter.orderColumn);
  columnToSort.defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

  return (
    <>
      <Row>
        <Col span={16}>
          <h4>Faqe Statike</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={getSiteUrl(`/pages/manage-page`)} />
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={pagedList.items}
        onChange={onChange}
        loading={loading}
        pagination={{
          total: pagedList.totalItemCount,
          pageSize: pagedList.pageSize,
          current: pagedList.pageNumber
        }}
      />
    </>
  );
};

export default Page;
