import axios from "axios";

class HttpClient {
  constructor(baseUrl) {
    this._baseUrl = baseUrl;
  }

  _getAxiosInstance() {
    return axios.create({
      baseURL: this._baseUrl,
      headers: this.getHeaders()
    });
  }

  getHeaders() {
    return {};
  }

  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      this._getAxiosInstance()
          .get(url, {
            params
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this._getAxiosInstance()
        .post(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }

  put(url, data, options = {}) {
    return new Promise((resolve, reject) => {
      this._getAxiosInstance()
        .put(url, data, options)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this._getAxiosInstance()
        .delete(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
}

export default HttpClient;
const httpClient = new HttpClient();
export { httpClient };
