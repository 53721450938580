import {adminHttpClient} from "../commons/adminHttpClient";
import ProgramFilter from "./ProgramFilter";

export default {
  getAll: async (filter = new ProgramFilter()) => {
    if (!filter) {
      throw new Error("Filtri nuk duhet të jetë bosh.");
    }

    return adminHttpClient.get(`/programs`, filter);
  },
  delete: async id => adminHttpClient.delete(`/programs/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/programs/forSave/${id}`);
    }
    return adminHttpClient.get(`/programs/forSave`);
  },
  weekDays: async () => adminHttpClient.get(`/programs/weekDays`),
  save: async program => adminHttpClient.post(`/programs`, program)
};
