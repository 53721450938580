import { adminHttpClient } from "../commons/adminHttpClient";

export default {
  getAll: async () => adminHttpClient.get("/broadcastCategories"),
  delete: async id => adminHttpClient.delete(`/broadcastCategories/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/broadcastCategories/forSave/${id}`);
    }
    return adminHttpClient.get(`/broadcastCategories/forSave`);
  },
  save: async category => adminHttpClient.post(`/broadcastCategories`, category)
};
