import OrderedPaginationFilter from "../commons/orderedPaginationFilter";

export default class ArticlesFilter extends OrderedPaginationFilter {
    constructor({
                    pageNumber = 1,
                    orderColumn = "created",
                    orderDescending = true,
                    searchValue = "",
                    languageId = ""
                } = {}) {
        super({
            pageNumber,
            orderColumn,
            orderDescending,
            searchValue
        });
        this.languageId = languageId;
    }
}
