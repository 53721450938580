import React, { useContext, useState, useEffect } from "react";
import { Row, Table, Col, Spin } from "antd";

import { queryRequest } from "../../common/utilities";
import TableEditButton from "../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../common/components/tables/TableDeleteButton";
import AddButton from "../../common/components/tables/AddButton";
import getSiteUrl from "../../siteUrlCreator";
import publicationCategories from "core/application/publications/publicationCategories";
import { AppContext } from "../../AppContext";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const PublicationCategory = () => {
  const { user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateTable = async () => {
    setLoading(true);

    const items = await queryRequest(() => publicationCategories.getAll());
    setData(items);

    setLoading(false);
  };

  useEffect(() => {
    updateTable();
  }, []);

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <TableEditButton
            className="mr-16"
            editUrl={`/publicationCategories/manage-category/${record.id}`}
            visible={user.canSaveArticle}
          />
          <TableDeleteButton
            onDelete={() => publicationCategories.delete(record.id)}
            onDeleted={() => updateTable()}
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>Kategoritë e Publikimeve</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton
              addUrl={getSiteUrl(`/publicationCategories/manage-category`)}
            />
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} onChange={onChange} />
    </Spin>
  );
};

export default PublicationCategory;
