import {Switch} from "react-router-dom";
import React from "react";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Banners from "../banners/Banners";
import ManageBanner from "../banners/ManageBanner";
import Event from "../events/Event";
import ManageEvent from "../events/ManageEvent";
import EventsCategories from "../events/categories/EventsCategories";
import ManageEventsCategory from "../events/categories/ManageEventsCategory";
import Galleries from "../galleries/Galleries";
import GalleryItems from "../galleries/GalleryItems";
import ManageGallery from "../galleries/ManageGallery";
import ManageGalleryItem from "../galleries/ManageGalleryItem";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";
import Menu from "../menus/Menu";
import Page from "../pages/Page";
import ManagePage from "../pages/ManagePage";
import Articles from "../articles/Articles";
import ManageArticle from "../articles/ManageArticle";
import BlogCategories from "../articles/categories/BlogCategories";
import ManageBlogCategory from "../articles/categories/ManageBlogCategory";
import Video from "../videos/Video";
import ManageVideo from "../videos/ManageVideo";

const ORCHESTRA_ROOT_PATH = "/orchestra";

const user = getLoginUser();

const orchestraRoutes = [
    {
        icon: "pie-chart",
        key: "menu-8",
        path: `${ORCHESTRA_ROOT_PATH}/articles`,
        name: "Artikuj",
        children: [
            {
                icon: "pie-chart",
                key: "menu-81",
                path: `${ORCHESTRA_ROOT_PATH}/articles`,
                name: "Të gjithë artikujt"
            },
            {
                icon: "pie-chart",
                key: "menu-82",
                path: `${ORCHESTRA_ROOT_PATH}/blogCategories`,
                name: "Kategoritë"
            }
        ]
    }
];

if (user && user.canDoAnything) {
    orchestraRoutes.push(
        {
            icon: "pie-chart",
            key: "menu-1",
            path: `${ORCHESTRA_ROOT_PATH}/banners`,
            name: "Banera"
        },
        {
            icon: "pie-chart",
            key: "menu-3",
            path: `${ORCHESTRA_ROOT_PATH}/events`,
            name: "Evente",
            children: [
                {
                    icon: "pie-chart",
                    key: "menu-3",
                    path: `${ORCHESTRA_ROOT_PATH}/events`,
                    name: "Të gjitha eventet"
                },
                {
                    icon: "pie-chart",
                    key: "menu-4",
                    path: `${ORCHESTRA_ROOT_PATH}/eventsCategories`,
                    name: "Kategori Eventesh"
                }
            ]
        },
        {
            icon: "pie-chart",
            key: "menu-8",
            path: `${ORCHESTRA_ROOT_PATH}/video`,
            name: "Video"
        },
        {
            icon: "pie-chart",
            key: "menu-5",
            path: `${ORCHESTRA_ROOT_PATH}/galleries`,
            name: "Galeria"
        },
        {
            icon: "pie-chart",
            key: "menu-6",
            path: `${ORCHESTRA_ROOT_PATH}/menu`,
            name: "Menu"
        },
        {
            icon: "pie-chart",
            key: "menu-7",
            path: `${ORCHESTRA_ROOT_PATH}/pages`,
            name: "Faqe statike"
        },
    );
}

const OrchestraRouter = () => (
    <Switch>
        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/banners`}>
            <Banners/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]} exact
                        path={`${ORCHESTRA_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}>
            <ManageBanner/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/events`}>
            <Event/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${ORCHESTRA_ROOT_PATH}/events/manage-event/:id?`}><ManageEvent/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/eventsCategories`}>
            <EventsCategories/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${ORCHESTRA_ROOT_PATH}/eventsCategories/manage-events-category/:id?`}>
            <ManageEventsCategory/>
        </ProtectedRoute>

        <ProtectedRoute exact path={`${ORCHESTRA_ROOT_PATH}/articles`}>
            <Articles/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${ORCHESTRA_ROOT_PATH}/articles/manage-article/:id?`}>
            <ManageArticle/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/blogCategories`}>
            <BlogCategories/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${ORCHESTRA_ROOT_PATH}/blogCategories/manage-category/:id?`}>
            <ManageBlogCategory/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/galleries`}>
            <Galleries/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${ORCHESTRA_ROOT_PATH}/galleries/manage-gallery/:id?`}>
            <ManageGallery/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${ORCHESTRA_ROOT_PATH}/gallery-items/:friendlyUrl`}>
            <GalleryItems/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${ORCHESTRA_ROOT_PATH}/gallery-items/manage-gallery-item/:galleryId/:friendlyUrl/:id?`}>
            <ManageGalleryItem/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/menu`}>
            <Menu/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/pages`}>
            <Page/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/pages/manage-page/:id?`}>
            <ManagePage/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/video`}>
            <Video/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]} exact path={`${ORCHESTRA_ROOT_PATH}/video/manage-video/:id?`}>
            <ManageVideo/>
        </ProtectedRoute>
    </Switch>
);

export {OrchestraRouter, ORCHESTRA_ROOT_PATH, orchestraRoutes};
