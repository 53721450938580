const Keys = {
  TOKEN: "token"
};

const authStore = {
  setToken: token => {
    localStorage.setItem(Keys.TOKEN, token);
  },
  getToken: () => localStorage.getItem(Keys.TOKEN)
};

export default authStore;
