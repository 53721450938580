import React, {useState, useEffect, useContext} from "react";
import * as Yup from "yup";
import generateFriendlyUrl from "../../common/generateFriendlyUrl";
import openNotification from "../../common/components/Notification";
import {useHistory, useParams} from "react-router-dom";
import Card from "antd/es/card";
import {Form as FormikForm, Formik} from "formik";
import Input from "../../common/components/forms/TextInput";
import {Spin} from "antd";
import Row from "antd/es/grid/row";
import ValidationSummary from "../../common/components/forms/ValidationSummary";
import {commandRequest, queryRequest} from "../../common/utilities";
import SaveCancelButtons from "../../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../../siteUrlCreator";
import Uploader from "../../common/components/forms/Uploader";
import {AppContext} from "../../AppContext";
import audioCategories from "core/application/audios/categories/audioCategories";

const validationRules = {
    name: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
    friendlyUrl: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
    photoId: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
}
const contactFormValidator = Yup.object(validationRules);

const ManageAudioCategory = () => {
    let history = useHistory();
    const {user, site} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState(null);
    const [errors, setErrors] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const {id} = useParams();
    const pageTitle = id ? "Modifiko podcast" : "Krijo podcast";

    const updateForm = async () => {
        setLoading(true);

        const item = await queryRequest(() => audioCategories.getForSave(id));
        setItem(item);

        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            await updateForm();
        })();
    }, []);


    const onSubmit = async values => {
        setSaveLoading(true);

        const itemToSave = {
            ...values,
            priority: parseInt(values.priority, 10),
        };

        const result = await commandRequest(() => audioCategories.save(itemToSave));
        setSaveLoading(false);

        if (!result.errors) {
            openNotification("success", "Operacioni", "Ruajtja e podcast u bë me sukses.");
            history.push(getSiteUrl("/podcasts"));
        } else {
            setErrors(result.errors);
            window.scrollTo(0, 0);
        }
    };

    return (
        <Spin spinning={loading}>
            <Card title={pageTitle}>
                <ValidationSummary errors={errors}/>
                {item && (
                    <Formik
                        enableReinitialize={true}
                        initialValues={item}
                        validationSchema={contactFormValidator}
                        onSubmit={onSubmit}>
                        {props => (
                            <FormikForm>
                                <Row>
                                    <Input
                                        label="Emri"
                                        name="name"
                                        type="text"
                                        placeholder="Emri i podcast"
                                        onBlur={e => {
                                            props.setFieldValue("friendlyUrl", generateFriendlyUrl(e.currentTarget.value));
                                        }}
                                    />
                                </Row>

                                <Row>
                                    <Input label="Url Miqësore" name="friendlyUrl" type="text"
                                           placeholder="Url Miqësore"/>
                                </Row>

                                <Row>
                                    <Uploader label="Foto(640 x 426)" initialValue={item.photoId}
                                              name="photoId"
                                              resizeToWidth={640}
                                              resizeToHeight={426}
                                              fieldName="photoId"
                                    />
                                </Row>

                                <Row>
                                    <Input label="Prioriteti" name="priority" type="text" placeholder="Prioriteti"/>
                                </Row>

                                <Row>
                                    <SaveCancelButtons loading={saveLoading}/>
                                </Row>
                            </FormikForm>
                        )}
                    </Formik>
                )}
            </Card>
        </Spin>
    );
};

export default ManageAudioCategory;
