import React, { useState } from "react";
import { Switch as AntSwitch } from "antd";
import StyledLabel from "./StyledLabel";
import Error from "./FormItemError";
import { useField, useFormikContext } from "formik";

const Switch = ({ label, defaultChecked, fieldName, ...props }) => {
  const [field, meta] = useField(props);
  const context = useFormikContext();
  const [value, setValue] = useState(defaultChecked);

  const onChange = checked => {
    context.setFieldValue(fieldName, checked);
    setValue(checked);
  };

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <AntSwitch defaultChecked={defaultChecked} onChange={onChange} />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

export default Switch;
