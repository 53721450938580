import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import { Spin } from "antd";
import Row from "antd/es/grid/row";

import openNotification from "../common/components/Notification";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import zodiacSign from "core/application/horoscopes/zodiacSign";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import Editor from "../common/components/forms/Editor";
import getSiteUrl from "../siteUrlCreator";

const contactFormValidator = Yup.object({
  content: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManageZodiacSign = () => {
  let history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState();
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const pageTitle = id ? "Modifiko shenjën e horoskopit" : "Krijo shenjën e horoskopit";

  const updateForm = async () => {
    const zodiac = await queryRequest(() => zodiacSign.getForSave(id));
    if (zodiac === "") {
      setItem({
        content: ""
      });
      return;
    }
    setItem(zodiac);
  };

  useEffect(() => {
    (async () => {
      await updateForm();
      setLoading(false);
    })();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    values.zodiacSignId = id;
    const result = await commandRequest(() => zodiacSign.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e shenjave të horoskopit u krye me sukses."
      );
      history.push(getSiteUrl("/horoscopes"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Editor
                    label="Përmbajtja"
                    initialValue={item.content}
                    name="content"
                    fieldName="content"
                  />
                </Row>
                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageZodiacSign;
