import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import openNotification from "../common/components/Notification";
import {useHistory, useParams} from "react-router-dom";
import Card from "antd/es/card";
import {Select as AntSelect, Skeleton} from "antd";
import Row from "antd/es/grid/row";
import {Form as FormikForm, Formik} from "formik";
import Input from "../common/components/forms/TextInput";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import manageUsers from "core/application/manageUsers/manageUsers";
import {commandRequest, queryRequest} from "../common/utilities";
import Select from "../common/components/forms/Select";
import SaveButton from "../common/components/forms/SaveButton";
import CancelButton from "../common/components/forms/CancelButton";
import {USERS_ROOT_PATH} from ".";
import StyledLabel from "../common/components/forms/StyledLabel";

const formValidator = Yup.object({
    name: Yup.string()
        .nullable()
        .trim()
        .required("Fushë e detyrueshme"),
    role: Yup.string().nullable().required("Fushë e detyrueshme")
});

const UpdateUser = () => {
    let history = useHistory();
    const {id} = useParams();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [roles, setRoles] = useState();
    const [sites, setSites] = useState([]);
    const [siteIds, setSiteIds] = useState([]);

    const updateForm = async () => {
        setLoading(true);

        const item = await queryRequest(() => manageUsers.getForUpdate(id));
        setItem(item);
        setRoles(item.availableRoles);
        setSites(item.availableSites);
        setSiteIds(item.sites)

        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            await updateForm();
        })();
    }, []);

    const onSubmit = async values => {
        setSaveLoading(true);

        const request = {
            name: values.name,
            roles: [parseInt(values.role)],
            siteIds: siteIds,
            id: item.id
        };

        const result = await commandRequest(() => manageUsers.updateUser(request));
        setSaveLoading(false);

        if (!result.errors) {
            openNotification("success", "Operacioni", "Ruajtja e përdoruesit u krye me sukses.");
            history.push(`${USERS_ROOT_PATH}/all`);
        } else {
            setErrors(result.errors);
        }
    };

    const onSiteIdsSelected = (value) => {
        setSiteIds(value);
    }

    return (
        <Skeleton loading={loading}>
            {item && (
                <Card title="Edito përdorues">
                    <ValidationSummary errors={errors}/>
                    <Formik
                        initialValues={item}
                        validationSchema={formValidator}
                        onSubmit={onSubmit}>
                        {formik => (
                            <FormikForm>
                                <Row>
                                    <Input label="Emri" name="name" type="text" placeholder="Emri i përdoruesit"/>
                                </Row>

                                <Row>
                                    <Select defaultValue={item.role} label="Roli" placeholder="Zgjidh rolin"
                                            name="role"
                                            itemName="role">
                                        <option value={null}>[Zgjidh rolin]</option>
                                        {roles.map(role => (
                                            <option key={role.key} value={role.key}>
                                                {role.value}
                                            </option>
                                        ))}
                                    </Select>
                                </Row>

                                <Row>
                                    <StyledLabel htmlFor={'siteIds'}>Zgjidhni Website-et</StyledLabel>
                                    <AntSelect defaultValue={item.sites} placeholder="Zgjidh website-et" name="siteIds"
                                               mode={'multiple'} onChange={onSiteIdsSelected}>
                                        {sites.map(site => (
                                            <option key={site.id} value={site.id}>
                                                {site.name}
                                            </option>
                                        ))}
                                    </AntSelect>
                                </Row>

                                <Row>
                                    <SaveButton loading={saveLoading}
                                                className="mr-16"
                                                disabled={!formValidator.isValidSync(formik.values)}
                                    />
                                    <CancelButton/>
                                </Row>
                            </FormikForm>
                        )}
                    </Formik>
                </Card>
            )}
        </Skeleton>
    );
};

export default UpdateUser;
