import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import { Spin } from "antd";
import Row from "antd/es/grid/row";

import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import galleries from "core/application/galleries/galleries";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import generateFriendlyUrl from "../common/generateFriendlyUrl";
import getSiteUrl from "../siteUrlCreator";

const contactFormValidator = Yup.object({
  name: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManageGallery = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const { id } = useParams();
  const pageTitle = id ? "Modifiko galerinë" : "Krijo galeri";

  const updateForm = async () => {
    setLoading(true);
    if (!id) {
      setItem({
        name: "",
        friendlyUrl: ""
      });
      setLoading(false);
      return;
    }

    const item = await queryRequest(() => galleries.getForSave(id));
    setItem(item);
    setLoading(false);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const onSubmit = async values => {
    setErrors([]);

    setSaveLoading(true);
    const result = await commandRequest(() => galleries.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e galerisë u krye me sukses."
      );
      history.push(getSiteUrl(`/galleries`));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Emri"
                    name="name"
                    type="text"
                    placeholder="Emri i galerisë"
                    onBlur={e => {
                      if (!item.friendlyUrl) {
                        props.setFieldValue(
                          "friendlyUrl",
                          generateFriendlyUrl(e.currentTarget.value)
                        );
                      }
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    label="Url miqësore"
                    name="friendlyUrl"
                    type="text"
                    placeholder="Url miqësore"
                  />
                </Row>
                <Row>
                  <div className={"text-center"}>
                    <SaveCancelButtons loading={saveLoading} />
                  </div>
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageGallery;
