import React, { useState, useEffect } from "react";
import { Row, Table, Col, Spin } from "antd";
import moment from "moment";

import { queryRequest } from "../common/utilities";
import EditDeleteButtons from "../common/components/tables/EditDeleteButtons";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import broadcastCategories from "core/application/broadcastCagegories/broadcastCategories";
import {
  displayDateAndHour,
  displayNamedItem
} from "../common/displayUtilities";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const BroadcastCategories = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateTable = async () => {
    setLoading(true);

    const items = await queryRequest(() => broadcastCategories.getAll());
    setData(items);

    setLoading(false);
  };

  useEffect(() => {
    updateTable();
  }, []);

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Prioriteti",
      dataIndex: "priority",
      sorter: (a, b) => a.priority - b.priority,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Prindi",
      dataIndex: "parent",
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      sortDirections: ["descend", "ascend"],
      render: value => displayNamedItem(value)
    },
    {
      title: "Krijuar në",
      dataIndex: "created",
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <EditDeleteButtons
          editUrl={getSiteUrl(
            `/broadcastCategories/manage-broadcast-category/${record.id}`
          )}
          onOkDelete={() => broadcastCategories.delete(record.id)}
          onDeleted={() => updateTable()}
          disableDeleted={record.hasChildren}
          deleteTitle={
            record.hasChildren ? "Nuk mund të fshihet sepse ka fëmijë." : ""
          }
        />
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>Kategoritë e Programeve</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton
              addUrl={getSiteUrl(
                `/broadcastCategories/manage-broadcast-category`
              )}
            />
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} onChange={onChange} />
    </Spin>
  );
};

export default BroadcastCategories;
