import {FESTIVAL_ROOT_PATH, festivalRoutes} from "./festivals";
import {RTSH_SPORT_ROOT_PATH, rtshSportRoutes} from "./rtshSport";
import {RTSH_ROOT_PATH, rtshRoutes} from "./rtsh";
import {RTSH24_ROOT_PATH, rtsh24Routes} from "./rtsh24";
import {TV_ROOT_PATH, tvRoutes} from "./tv";
import {RTI_ROOT_PATH, rtiRoutes} from "./rti";
import {RADIO_ROOT_PATH, radioRoutes} from "./radio";
import {ORCHESTRA_ROOT_PATH, orchestraRoutes} from "./orchestra";
import userStore from "core/application/users/userStore";
import {SiteIds} from "core/domain/sites/site";

const getSiteRoutes = user => {
    const mappings = {};
    mappings[SiteIds.FESTIVAL_ID] = festivalRoutes;
    mappings[SiteIds.RTSH_SPORT_ID] = rtshSportRoutes;
    mappings[SiteIds.RTSH_24_ID] = rtsh24Routes;
    mappings[SiteIds.RTSH_MAIN] = rtshRoutes;
    mappings[SiteIds.RTSH_TV] = tvRoutes;
  mappings[SiteIds.RTSH_RTI] = rtiRoutes;
  mappings[SiteIds.RTSH_RADIO] = radioRoutes;
  mappings[SiteIds.RTSH_ORCHESTRA] = orchestraRoutes;
  return mappings[user.currentSiteId];
};

const getSiteRootPath = user => {
  const mappings = { };
  mappings[SiteIds.FESTIVAL_ID] = FESTIVAL_ROOT_PATH;
  mappings[SiteIds.RTSH_SPORT_ID] = RTSH_SPORT_ROOT_PATH;
  mappings[SiteIds.RTSH_24_ID] = RTSH24_ROOT_PATH;
  mappings[SiteIds.RTSH_MAIN] = RTSH_ROOT_PATH;
  mappings[SiteIds.RTSH_TV] = TV_ROOT_PATH;
  mappings[SiteIds.RTSH_RTI] = RTI_ROOT_PATH;
  mappings[SiteIds.RTSH_RADIO] = RADIO_ROOT_PATH;
  mappings[SiteIds.RTSH_ORCHESTRA] = ORCHESTRA_ROOT_PATH;
  return mappings[user.currentSiteId];
};

const getCurrentSite = () => {
  const user = userStore.getUser();
  if (user == null) {
    throw new Error("Përdoruesi i kyçur nuk ekziston");
  }
  return {
      ROOT_PATH: getSiteRootPath(user),
      ROUTES: getSiteRoutes(user),
      showLanguages: user.currentSiteId === SiteIds.RTSH_RTI,
      showChangeCurrentFestival: user.currentSiteId === SiteIds.FESTIVAL_ID,
  };
};

export default getCurrentSite;
