import React, { useContext, useState, useEffect } from "react";
import { Row, Table, Col, Spin } from "antd";
import { useHistory } from "react-router-dom";

import zodiacSign from "core/application/horoscopes/zodiacSign";
import { queryRequest } from "../common/utilities";
import getSiteUrl from "../siteUrlCreator";
import TableEditButton from "../common/components/tables/TableEditButton";
import AddButton from "../common/components/tables/AddButton";
import { AppContext } from "../AppContext";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const ZodiacSign = () => {
  const { user } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateTable = async () => {
    setLoading(true);

    const items = await queryRequest(() => zodiacSign.getZodiacSigns());
    setData(items);
    setLoading(false);
  };

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <TableEditButton
            className="mr-16"
            editUrl={`/horoscopes/manage-zodiacsign/${record.id}`}
            visible={user.canSaveArticle}
          />
        </>
      )
    }
  ];

  useEffect(() => {
    updateTable();
  }, []);
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>Shenjat e Horoskopit</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={getSiteUrl(`/horoscopes/manage-zodiacsign`)} />
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} onChange={onChange} />
    </Spin>
  );
};

export default ZodiacSign;
