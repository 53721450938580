import { Switch } from "react-router-dom";
import React from "react";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Articles from "../articles/Articles";
import ManageArticle from "../articles/ManageArticle";
import Publication from "../publications/Publication";
import ManagePublication from "../publications/ManagePublication";
import PublicationCategory from "../publications/categories/PublicationCategory";
import ManagePublicationCategory from "../publications/categories/ManagePublicationCategory";
import Banners from "../banners/Banners";
import ManageBanner from "../banners/ManageBanner";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";

const RTSH_ROOT_PATH = "/rtsh";

const user = getLoginUser();

const rtshRoutes = [
  {
    icon: "pie-chart",
    key: "menu-2",
    path: `${RTSH_ROOT_PATH}/publications`,
    name: "Publikime"
  }
];

if (user && user.canDoAnything) {
  rtshRoutes.push(
    {
      icon: "pie-chart",
      key: "menu-3",
      path: `${RTSH_ROOT_PATH}/publicationCategories`,
      name: "Kategoritë e Publikimeve"
    },
    {
      icon: "pie-chart",
      key: "menu-4",
      path: `${RTSH_ROOT_PATH}/banners`,
      name: "Banera"
    }
  );
}

const RtshRouter = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${RTSH_ROOT_PATH}/articles`}
    >
      <Articles />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_ROOT_PATH}/articles/manage-article/:id?`}
    >
      <ManageArticle />
    </ProtectedRoute>
    <ProtectedRoute
      exact
      path={`${RTSH_ROOT_PATH}/publications`}
    >
      <Publication />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_ROOT_PATH}/publication/manage-publication/:id?`}
    >
      <ManagePublication />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_ROOT_PATH}/publicationCategories`}
    >
      <PublicationCategory />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_ROOT_PATH}/publicationCategories/manage-category/:id?`}
    >
      <ManagePublicationCategory />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_ROOT_PATH}/banners`}
    >
      <Banners />
    </ProtectedRoute>
    <ProtectedRoute exact path={`${RTSH_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}>
      <ManageBanner />
    </ProtectedRoute>
  </Switch>
);

export { RtshRouter, RTSH_ROOT_PATH, rtshRoutes };
