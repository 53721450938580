import {Switch} from "react-router-dom";
import React from "react";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Authors from "../authors/Authors";
import Channels from "../channels/Channels";
import ManageChannel from "../channels/ManageChannel";
import Banners from "../banners/Banners";
import ManageBanner from "../banners/ManageBanner";
import ManageAuthor from "../authors/ManageAuthor";
import ManageBroadcastCategories from "../broadcastCategories/ManageBroadcastCategories";
import BroadcastCategories from "../broadcastCategories/BroadcastCategories";
import Programs from "../programs/Programs";
import ManageProgram from "../programs/ManageProgram";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";

const TV_ROOT_PATH = "/tv";
const user = getLoginUser();

const tvRoutes = [
    {
        icon: "pie-chart",
        key: "menu-3",
        path: `${TV_ROOT_PATH}/programs`,
        name: "Programet"
    }
];

if (user && user.canDoAnything) {
    tvRoutes.push({
        icon: "pie-chart",
        key: "menu-1",
        path: `${TV_ROOT_PATH}/banners`,
        name: "Banera"
    });

    tvRoutes.push({
        icon: "pie-chart",
        key: "menu-2",
        path: `${TV_ROOT_PATH}/channels`,
        name: "Kanalet"
    });
}

const TvRouter = () => (
    <Switch>
        <ProtectedRoute permissions={[Permission.All]} exact path={`${TV_ROOT_PATH}/banners`}>
            <Banners/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]}
                        exact
                        path={`${TV_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}>
            <ManageBanner/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${TV_ROOT_PATH}/channels`}>
            <Channels/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]} exact path={`${TV_ROOT_PATH}/channels/manage-channel/:id?`}>
            <ManageChannel/>
        </ProtectedRoute>

        <ProtectedRoute exact path={`${TV_ROOT_PATH}/programs`}>
            <Programs/>
        </ProtectedRoute>
        <ProtectedRoute permissions={[Permission.All]} exact path={`${TV_ROOT_PATH}/programs/manage-program/:id?`}>
            <ManageProgram/>
        </ProtectedRoute>
    </Switch>
);

export {TvRouter, TV_ROOT_PATH, tvRoutes};
