import React, { useContext, useState, useEffect } from "react";
import { Row, Table, Col, Spin } from "antd";
import moment from "moment";

import events from "core/application/events/events";
import { queryRequest } from "../common/utilities";
import TableEditButton from "../common/components/tables/TableEditButton";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import { displayDateAndHour } from "../common/displayUtilities";
import PagedList from "../common/models/pagedList";
import OrderedPaginationFilter from "core/application/commons/orderedPaginationFilter";
import { AppContext } from "../AppContext";

const Events = () => {
  const { user } = useContext(AppContext);
  const [pagedList, setPagedList] = useState(new PagedList());
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(
    new OrderedPaginationFilter({
      orderColumn: "created"
    })
  );

  const updatePagedEvents = async () => {
    setLoading(true);
    const result = await queryRequest(() => events.getAll(filter));
    setPagedList(result);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updatePagedEvents();
    })();
  }, [filter]);

  const onChange = (pagination, filters, sorter) => {
    const eventFilter = new OrderedPaginationFilter({
      orderColumn: sorter.field,
      orderDescending: sorter.order !== "ascend",
      pageNumber: pagination.current
    });
    setFilter(eventFilter);
  };

  const columns = [
    {
      title: "Titulli",
      dataIndex: "title",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <a
          className={"dark"}
          href={`https://festivali.rtsh.al/event/${record.friendlyUrl}`}
        >
          {text}
        </a>
      )
    },
    {
      title: "Krijuar në",
      dataIndex: "created",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <TableEditButton
            className="mr-16"
            editUrl={`/events/manage-event/${record.id}`}
            visible={user.canSaveArticle}
          />
          <TableDeleteButton
            onDelete={() => events.delete(record.id)}
            onDeleted={() => updatePagedEvents()}
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  columns.find(
    c => c.dataIndex === filter.orderColumn
  ).defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>Evente</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={getSiteUrl(`/events/manage-event`)} />
          </div>
        </Col>
      </Row>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={pagedList.items}
        onChange={onChange}
        loading={loading}
        pagination={{
          total: pagedList.totalItemCount,
          pageSize: pagedList.pageSize,
          current: pagedList.pageNumber
        }}
      />
    </Spin>
  );
};

export default Events;
