import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Table } from "antd";

import publication from "core/application/publications/publication";
import { queryRequest } from "../common/utilities";
import TableEditButton from "../common/components/tables/TableEditButton";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import { AppContext } from "../AppContext";
import PagedList from "../common/models/pagedList";

import OrderedPaginationFilter from "core/application/commons/orderedPaginationFilter";

const Publication = () => {
  const { user } = useContext(AppContext);
  const [pagedList, setPagedList] = useState(new PagedList());
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(
    new OrderedPaginationFilter({
      orderColumn: "created"
    })
  );

  const updatePagedPublications = async () => {
    setLoading(true);
    const result = await queryRequest(() => publication.getAll(filter));
    setPagedList(result);

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updatePagedPublications();
    })();
  }, [filter]);

  const onChange = (pagination, filters, sorter) => {
    const publicationFilter = new OrderedPaginationFilter({
      orderColumn: sorter.field,
      orderDescending: sorter.order !== "ascend",
      pageNumber: pagination.current
    });
    setFilter(publicationFilter);
  };

  const columns = [
    {
      title: "Titulli",
      dataIndex: "title",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <a
          className={"dark"}
          href={`https://festivali.rtsh.al/artikull/${record.friendlyUrl}`}
        >
          {text}
        </a>
      )
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <TableEditButton
            className="mr-16"
            editUrl={`/publication/manage-publication/${record.id}`}
            visible={user.canSaveArticle}
          />
          <TableDeleteButton
            onDelete={() => publication.delete(record.id)}
            onDeleted={() => updatePagedPublications()}
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  return (
    <>
      <Row>
        <Col span={16}>
          <h4>Publikime</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={getSiteUrl(`/publication/manage-publication`)} />
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={pagedList.items}
        onChange={onChange}
        loading={loading}
        pagination={{
          total: pagedList.totalItemCount,
          pageSize: pagedList.pageSize,
          current: pagedList.pageNumber
        }}
      />
    </>
  );
};

export default Publication;
