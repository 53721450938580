import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Page from "./Page";
import ManagePage from "./ManagePage";

const PAGE_ROOT_PATH = "/page";

const pageMainRoute = {
  icon: "pie-chart",
  key: "menu-10",
  path: `${PAGE_ROOT_PATH}/`,
  name: "Faqe Statike"
};

const PageRouter = () => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${PAGE_ROOT_PATH}/`}>
        <Page />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${PAGE_ROOT_PATH}/manage-page/:id?`}>
        <ManagePage />
      </ProtectedRoute>
    </Switch>
  );
};

export { PageRouter, PAGE_ROOT_PATH, pageMainRoute };
