import {Switch} from "react-router-dom";
import React from "react";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Articles from "../articles/Articles";
import ManageArticle from "../articles/ManageArticle";
import BlogCategories from "../articles/categories/BlogCategories";
import ManageBlogCategory from "../articles/categories/ManageBlogCategory";
import Banners from "../banners/Banners";
import ManageBanner from "../banners/ManageBanner";
import ReportMessage from "../reportMessages/ReportMessage";
import ReportMessages from "../reportMessages/ReportMessages";
import ZodiacSign from "../horoscopes/ZodiacSign";
import ManageZodiacSign from "../horoscopes/ManageZodiacSign";
import Video from "../videos/Video";
import ManageVideo from "../videos/ManageVideo";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";

const RTSH24_ROOT_PATH = "/rtsh24";

const user = getLoginUser();

const rtsh24Routes = [
  {
    icon: "pie-chart",
    key: "menu-1",
    path: `${RTSH24_ROOT_PATH}/articles`,
    name: "Artikuj"
  }
];

if (user && user.canDoAnything) {
  rtsh24Routes.push(
    {
      icon: "pie-chart",
        key: "menu-2",
        path: `${RTSH24_ROOT_PATH}/blogCategories`,
        name: "Kategoritë"
    },
      {
          icon: "pie-chart",
          key: "menu-3",
          path: `${RTSH24_ROOT_PATH}/banners`,
          name: "Banera"
      },
      // {
      //   icon: "pie-chart",
      //   key: "menu-4",
      //   path: `${RTSH24_ROOT_PATH}/reportMessages`,
      //   name: "Raporto Tani"
      // },
      // {
      //   icon: "pie-chart",
      //   key: "menu-5",
      //   path: `${RTSH24_ROOT_PATH}/horoscopes`,
      //   name: "Horoskopi"
      // },
      // {
      //   icon: "pie-chart",
      //   key: "menu-6",
      //   path: `${RTSH24_ROOT_PATH}/video`,
      //   name: "Video"
      // }
  );
}

const Rtsh24Router = () => (
  <Switch>
    <ProtectedRoute exact path={`${RTSH24_ROOT_PATH}/articles`}>
      <Articles />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[
        Permission.All,
        Permission.PublishArticle,
        Permission.SaveArticle
      ]}
      exact
      path={`${RTSH24_ROOT_PATH}/articles/manage-article/:id?`}
    >
      <ManageArticle />
    </ProtectedRoute>

    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/blogCategories`}
    >
      <BlogCategories />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/blogCategories/manage-category/:id?`}
    >
      <ManageBlogCategory />
    </ProtectedRoute>

    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/banners`}
    >
      <Banners />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}
    >
      <ManageBanner />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/reportMessages`}
    >
      <ReportMessages />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/reportMessages/:id`}
    >
      <ReportMessage />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/horoscopes`}
    >
      <ZodiacSign />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/horoscopes/manage-zodiacsign/:id`}
    >
      <ManageZodiacSign />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/video`}
    >
      <Video />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH24_ROOT_PATH}/video/manage-video/:id?`}
    >
      <ManageVideo />
    </ProtectedRoute>
  </Switch>
);

export { Rtsh24Router, RTSH24_ROOT_PATH, rtsh24Routes };
