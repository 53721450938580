import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import Row from "antd/es/grid/row";
import { Spin } from "antd";

import generateFriendlyUrl from "../common/generateFriendlyUrl";
import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import channels from "core/application/channels/channels";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";

const contactFormValidator = Yup.object({
  name: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  photoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  color: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManageChannel = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko kanalin" : "Krijo kanal";

  const updateForm = async () => {
    setLoading(true);
    const item = await queryRequest(() => channels.getForSave(id));
    setItem(item);
    setLoading(false);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);

    const result = await commandRequest(() => channels.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e artikullit u krye me sukses."
      );
      history.push(getSiteUrl("/channels"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Emri"
                    name="name"
                    type="text"
                    placeholder="Emri i kanalit"
                    onBlur={e => {
                      props.setFieldValue(
                        "friendlyUrl",
                        generateFriendlyUrl(e.currentTarget.value)
                      );
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    label="Url miqësore"
                    name="friendlyUrl"
                    type="text"
                    placeholder="Url miqësore"
                  />
                </Row>

                <Row>
                  <Uploader
                    label="Logo"
                    initialValue={item.photoId}
                    name="photoId"
                    fieldName="photoId"
                    resizeToHeight={20}
                    resizeToWidth={100}
                  />
                </Row>

                <Row>
                  <Input
                    label="Ngjyra"
                    name="color"
                    type="text"
                    placeholder="Ngjyra"
                  />
                </Row>

                <Row>
                  <Input
                    label="Livestream Url (Opsionale)"
                    name="liveStreamUrl"
                    type="text"
                    placeholder="Livestream Url"
                  />
                </Row>

                <Row>
                  <Input
                    label="Url e fotos livestream (Opsionale)"
                    name="liveStreamPhotoUrl"
                    type="text"
                    placeholder="Url e fotos livestream"
                  />
                </Row>

                <Row>
                  <Input
                    label="ID e jashtme (Opsionale)"
                    name="externalId"
                    type="text"
                    placeholder="ID e jashtme"
                  />
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageChannel;
