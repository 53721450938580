import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Card } from "antd";

import reportMessages from "core/application/reportMessages/reportMessages";
import { queryRequest } from "../common/utilities";
import getSiteUrl from "../siteUrlCreator";
import { displayDateAndHour } from "../common/displayUtilities";

const ReportMessage = () => {
  let history = useHistory();

  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (id) {
        loadMessage();
        setLoading(false);
      } else {
        history.push(getSiteUrl("/reportMessages"));
      }
      setLoading(false);
    })();
  }, []);

  const loadMessage = async () => {
    const item = await queryRequest(() => reportMessages.getById(id));
    setItem(item);
  };

  return (
    <Row>
      {item && (
        <div>
          <Card>
            <div className="mb-24">
              <img src={item.fileUrl} />
            </div>
            <Card
              title={`Mesazh i dërguar në ${displayDateAndHour(item.Created)}`}
            >
              {item.content}
            </Card>
          </Card>
        </div>
      )}
    </Row>
  );
};

export default ReportMessage;
