import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../../../AppContext";

const ProtectedRoute = ({ permissions = [], ...rest }) => {
  const { user } = useContext(AppContext);
  return (
    <Route exact path={rest.path}>
      {isAuthorized(user, permissions) ? (
        rest.children
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};

const isAuthorized = (user, allowedPermissions) => {
  if (!user) {
    return false;
  }

  if (allowedPermissions.length === 0) {
    return true;
  }

  for (let i = 0; i < allowedPermissions.length; i++) {
    for (let j = 0; j < user.permissions.length; j++) {
      if (user.permissions[j] === allowedPermissions[i]) {
        return true;
      }
    }
  }

  return false;
};

export default ProtectedRoute;
