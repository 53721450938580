import { adminHttpClient } from "../commons/adminHttpClient";

export default {
  getAllBanners: () => adminHttpClient.get(`/banners`),
  getBannerWithItems: (bannerId) => adminHttpClient.get(`/banners/${bannerId}`),
  save: banner => adminHttpClient.post(`/banners`, banner),
  delete: async id => adminHttpClient.delete(`/banners/${id}`),
  getForSave: async (bannerId, id) => {
    if(id) {
      return adminHttpClient.get(`/banners/forSave/${bannerId}/${id}`);
    }
    return adminHttpClient.get(`/banners/forSave/${bannerId}`);
  },
};
