import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import {useHistory,  useParams} from "react-router-dom";
import Card from "antd/es/card";
import { Spin } from "antd";
import Row from "antd/es/grid/row";
import moment from "moment";

import openNotification from "../common/components/Notification";
import { Form as FormikForm, Formik } from "formik";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import galleryItems from "core/application/galleries/galleryItems";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";

const contactFormValidator = Yup.object({
  fileId: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
});

const ManageGalleryItem = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const { id, galleryId, friendlyUrl } = useParams();

  const pageTitle = id ? "Modifiko foton" : "Shto foto";

  const updateForm = async () => {
    setLoading(true);
    if (!id) {
      setItem({
        fileId: "",
        type: 0,
        title: "",
        content: "",
        dateTaken: moment()
      });
      setLoading(false);
      return;
    }

    const item = await queryRequest(() => galleryItems.getForSave(id));
    setItem(item);
    setLoading(false);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const onSubmit = async values => {
    setErrors([]);

    setSaveLoading(true);
    const result = await commandRequest(() => galleryItems.save({
      id: item.id,
      title: values.title,
      fileId: values.fileId,
      type: values.type,
      dateTaken: values.dateTaken,
      galleryId: galleryId
    }));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification("success", "Operacioni", "Ruajtja e imazhit u krye me sukses.");
      history.push(getSiteUrl(`/gallery-items/${friendlyUrl}`));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}>
            <FormikForm>
              <Row>
                <Uploader
                  label="Foto (640 x 426)"
                  initialValue={item.fileId}
                  name="fileId"
                  fieldName="fileId"
                />
              </Row>

              <Row>
                <Input
                  label="Titulli"
                  name="title"
                  type="text"
                  placeholder="Titulli i fotos"
                />
              </Row>

              <Row>
                <div className={"text-center"}>
                 <SaveCancelButtons loading={saveLoading}/>
                </div>
              </Row>

            </FormikForm>
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageGalleryItem;
