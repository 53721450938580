import { message } from "antd";

const queryRequest = async func => {
  try {
    return await func();
  } catch (err) {
    if (!err || !err.status) {
      message.error("Ka ndodhur një gabim gjatë marrjes së të dhënave!");
      return;
    }

    if (err.status === 401) {
      window.location = "/login";
    } else {
      message.error("Ka ndodhur një gabim gjatë marrjes së të dhënave!");
    }
  }
};

const commandRequest = async func => {
  try {
    return await func();
  } catch (err) {
    if (!err || !err.status) {
      return {
        errors: [
          "Ka ndodhur një gabim gjatë operacionit. Ju lutemi provoni përsëri."
        ]
      };
    }

    if (err.status === 401) {
      window.location = "/login";
    } else if (
      err.status &&
      err.status === 400 &&
      err.data &&
      err.data.length
    ) {
      return {
        errors: err.data.map(err => err.message)
      };
    } else {
      return {
        errors: [
          "Ka ndodhur një gabim gjatë operacionit. Ju lutemi provoni përsëri."
        ]
      };
    }
  }
};

export { queryRequest, commandRequest };
