import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const SaveCancelButtons = ({
  loading = false,
  saveTitle = "Ruaj",
  cancelTitle = "Anullo",
  backUrl = null,
  icon = "save",
  onClick = () => {}
}) => {
  let history = useHistory();

  const handleCancelClick = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <Button
        icon={icon}
        type="primary"
        htmlType="submit"
        onClick={onClick}
        className={"mr-16"}
        loading={loading}
      >
        {saveTitle}
      </Button>

      <Button onClick={handleCancelClick}>{cancelTitle}</Button>
    </>
  );
};

export default SaveCancelButtons;
