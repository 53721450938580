import OrderedPaginationFilter from "../../commons/orderedPaginationFilter";
import {adminHttpClient} from "../../commons/adminHttpClient";

const controller = "/audios/categories";

export default {
    getAll: async (filter = new OrderedPaginationFilter()) => {
        if (!filter) {
            throw new Error("Filtri nuk duhet të jetë bosh.");
        }

        return adminHttpClient.get(
            `${controller}?PageNumber=${filter.pageNumber}&orderColumn=${filter.orderColumn}&orderDescending=${filter.orderDescending}`
        );
    },
    getById: async id => adminHttpClient.get(`${controller}/${id}`),
    delete: async id => adminHttpClient.delete(`${controller}/${id}`),
    getForSave: async id => {
        if (id) {
            return adminHttpClient.get(`${controller}/forSave/${id}`);
        }
        return adminHttpClient.get(`${controller}/forSave`);
    },
    save: async item => adminHttpClient.post(`${controller}`, item),
};