export default class OrderedPaginationFilter {
  constructor({
    pageNumber = 1,
    orderColumn = "created",
    orderDescending = true,
    searchValue = ""
  } = {}) {
    this.pageNumber = pageNumber;
    this.orderColumn = orderColumn;
    this.orderDescending = orderDescending;
    this.searchValue = searchValue
  }
}
