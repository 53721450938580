import React from "react";
import { Switch } from "react-router-dom";

import SiteSettings from "./SiteSettings";
import ProtectedRoute from "../common/configs/routes/protectedRoute";

const SITE_ROOT_PATH = "/sites";

const mainRouteSite = {
  icon: "pie-chart",
  key: "menu-1",
  path: `${SITE_ROOT_PATH}`,
  name: "Konfigurime"
};

const SiteRouter = () => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${SITE_ROOT_PATH}`}>
        <SiteSettings />
      </ProtectedRoute>
    </Switch>
  );
};

export { SiteRouter, SITE_ROOT_PATH, mainRouteSite };
