import {adminHttpClient} from "../commons/adminHttpClient";
import ArticlesFilter from "./ArticlesFilter";

export default {
    getAll: async (filter = new ArticlesFilter()) => {
        if (!filter) {
            throw new Error("Filtri nuk duhet të jetë bosh.");
        }

        return adminHttpClient.get(`/articles`, filter);
    },
    delete: async id => adminHttpClient.delete(`/articles/${id}`),
    getForSave: async id => {
        if (id) {
            return adminHttpClient.get(`/articles/forSave/${id}`);
        }
        return adminHttpClient.get(`/articles/forSave`);
    },
    getHistoryLogForArticle: async id => adminHttpClient.get(`/articles/HistoryLog/${id}`),
    publish: async article => adminHttpClient.post(`/articles/publish`, article),
    saveAsDraft: async article => adminHttpClient.post(`/articles/saveAsDraft`, article),
    swapOrder: async (currentArticleId, previousArticleId) => {
        return adminHttpClient.put(`/articles/SwapArticlesOrder`, {previousArticleId, currentArticleId});
    }
};