import React from "react";

const ValidationSummary = ({ errors }) => {
  if (!errors || errors.length === 0) {
    return "";
  }

  return (
    <div className={"validation-summary"}>
      <ul>
        {errors.map(e => (
          <li>{e}</li>
        ))}
      </ul>
    </div>
  );
};

export default ValidationSummary;
