import React from "react";
import {Table, Tag} from "antd";
import {displayDateAndHour} from "../common/displayUtilities";

const ArticleHistoryTable = ({articleHistoryLog}) => {

    const columns = [
        {
            title: "Përdoruesi",
            dataIndex: "createdBy",
            render: (text, record) => `${record.createdBy.name}`
        },
        {
            title: "Operacioni",
            dataIndex: "operation",
            render: (text, record) => {
                return record.operation === 0
                    ? (<Tag color="green">Krijuar</Tag>)
                    : (<Tag color="orange">Modifikuar</Tag>)
            }
        },
        {
            title: "Data",
            dataIndex: "created",
            sorter: false,
            render: value => displayDateAndHour(value)
        },
    ];

    return (
        <>
            <Table
                rowKey={"id"}
                columns={columns}
                dataSource={articleHistoryLog}
            />
        </>
    );
};


export default ArticleHistoryTable;