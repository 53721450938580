import Permission from "../../domain/users/Permission";

class User {
  constructor({ userId, currentSiteId, name, site, permissions = [] }) {
    this.userId = userId;
    this.currentSiteId = currentSiteId;
    this.name = name;
    this.permissions = permissions;
    this.site = site;
  }

  get canPublishArticle() {
    return (
      this.permissions.includes(Permission.All) ||
      this.permissions.includes(Permission.PublishArticle)
    );
  }

  get canSaveArticle() {
    return (
      this.permissions.includes(Permission.All) ||
      this.permissions.includes(Permission.SaveArticle) ||
      this.permissions.includes(Permission.PublishArticle)
    );
  }

  get canDoAnything() {
    return this.permissions.includes(Permission.All);
  }

  get canDeleteArticle() {
    return (
      this.permissions.includes(Permission.All) ||
      this.permissions.includes(Permission.DeleteArticle)
    );
  }
}

export default User;
